











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class DraggableContainer extends Vue {
  @Prop({ required: true, type: String }) header!: string;
}
